import { createSlice } from '@reduxjs/toolkit'
import moment from 'moment'
import { getMomentDateNow } from '../../utils/utils'

export const selectDailyBeatSummaryById = (id) => (state) => state.dailyBeatsSummaries.dailyBeatsSummaries.find((dailyBeat) => id === dailyBeat.id)

export const selectDailyBeatsSummariesPreferenceId = (state) => state.dailyBeatsSummaries.selectedPreferenceId

const dailyBeatsSummaries = createSlice({
  name: 'dailyBeatsSummaries',
  initialState: {
    dailyBeatsSummaries: [{
      id: null, // str
      type: null, // str,
      label: null, // str
      name: null, // str
      subName: null, // str
      items: null, // Array<obj>
      firstItem: null, // obj
      currentItem: null, // obj
      imageUrl: null, // str
    }],
    selectedPreferenceId: null // str
  },
  reducers: {
    loadDailyBeatsSummaries: (state, { payload: dailyBeats }) => {
      state.dailyBeatsSummaries = dailyBeats.map((dailyBeat) => ({
          id: dailyBeat.id,
          type: dailyBeat.type,
          label: dailyBeat.label,
          name: dailyBeat.name,
          subName: dailyBeat.subName,
          items: dailyBeat.items,
          firstItem: dailyBeat.firstItem,
          currentItem: dailyBeat.firstItem,
          imageUrl: dailyBeat.imageUrl?.trim(),
      }))
    },
    updateDailyBeatsSummariesPreferenceId: (state, { payload: preferenceId }) => {
      state.selectedPreferenceId = preferenceId
    },
    updateDailyBeatSummaryCurrentItem: (state, { payload: { id, nextItem } }) => {
      const dailyBeat = state.dailyBeatsSummaries.find((db) => db.id === id)
      dailyBeat.currentItem = nextItem
    },
  },
})

export const { loadDailyBeatsSummaries, updateDailyBeatsSummariesPreferenceId, updateDailyBeatSummaryCurrentItem } = dailyBeatsSummaries.actions

export default dailyBeatsSummaries.reducer
