import { createSlice } from '@reduxjs/toolkit'
import moment from 'moment'

export const selectSchools = (state) => state.schools
export const selectSchool = (id) => (state) => state.schools.find((school) => school.id === id)

const schoolsSlice = createSlice({
  name: 'schools',
  initialState: [{
    name: null, // string
    id: null, // number
    city: null, // string
    state: null, // string,
    street: null, // string
    startDate: null, // string
    districtName: null, // string,
    type: null, // string
    notes: null, // string
    timezone: null, // string
    isQASchool: null, // boolean
  }],
  reducers: {
    loadSchools: (state, {
      payload: schools,
    }) => schools.map((school) => ({
      name: school.name,
      id: school.id,
      city: school.city,
      state: school.state,
      street: school.street,
      country: school.country,
      districtName: school.districtName,
      type: school.type,
      notes: school.note,
      timezone: school.timezone,
      startDate: school.startDate ? moment(school.startDate).format('YYYY-MM-DD') : null,
      isQASchool: school.isQASchool
    })),
  },
})

export const { loadSchools } = schoolsSlice.actions

export default schoolsSlice.reducer
