import { createSlice } from '@reduxjs/toolkit'
import moment from 'moment'
import { getMomentDateNow } from '../../utils/utils'

export const dailyBeatIsAvailable = (dailyBeat) => {
  const formattedMoment = moment(dailyBeat.availableDate).format(moment.HTML5_FMT.DATE)
  return formattedMoment <= getMomentDateNow()
}

export const dailyBeatIsInProgress = (dailyBeat) => dailyBeat.currentItem.id !== dailyBeat.firstItem.id

// We used to lock daily beats if the first lesson had not started.
// Now they are always unlocked
export const selectDailyBeatsAreUnlocked = (state) => dailyBeatIsAvailable(state.dailyBeats[0])

export const selectDailyBeats = (state) => state.dailyBeats

export const selectNumCompleteDailyBeats = (state) => state.dailyBeats.filter((db) => db.completed).length

export const selectDailyBeatById = (id) => (state) => state.dailyBeats.find((dailyBeat) => id === dailyBeat.id)

export const selectNextUnavailableDailyBeat = (state) => {
  // eslint-disable-next-line no-restricted-syntax
  for (const dailyBeat of state.dailyBeats) {
    if (!dailyBeatIsAvailable(dailyBeat)) return dailyBeat
  }
  // All daily beats are available
  return null
}

/*
-Daily Beat 1 stays featured on the dashboard until it’s completed
-Then once DB1 is complete, we feature DB2. We keep featuring it until it’s completed.
-Then we feature the current day’s DB (so if they are on day 7 of 22, it features DB7)
-Then if they’ve done DB1, DB2, and the current day’s DB, we feature the earliest DB that they haven’t completed yet (e.g. DB3 if so far they skipped DB3 and DB5)
-The logic behind this algorithm is that DB 1 and DB 2 have important instructional videos that teach students how to engage in future Daily Beats. But once these are done, there’s not an important order to how they are completed. And some teachers might want to talk to students about the DB afterwards, so there’s a small advantage in usually having students complete the same one in class together (which is why the up next algorithm features the current day’s DB over most of the older incomplete DBs).

 */
export const selectNextDailyBeat = (state) => {
  if (!state.dailyBeats[0].completed) {
    return state.dailyBeats[0]
  }
  // eslint-disable-next-line no-restricted-syntax
  for (const dailyBeat of state.dailyBeats) {
    if (!dailyBeat.completed && dailyBeatIsAvailable(dailyBeat)) return dailyBeat
  }
  // Nothing found means all available daily beats have been complete
  return null
}

const dailyBeats = createSlice({
  name: 'user',
  initialState: [{
    id: null, // str
    type: null, // str,
    label: null, // str
    name: null, // str
    subName: null, // str
    firstItem: null, // obj
    currentItem: null, // obj
    imageUrl: null, // str
    v2ImageUrl: null, // str
    description: null, // str
    duration: null, // number
    available: null, // bool
    availableDate: null, // str (timestamp)
    completed: null, // bool
    percentComplete: null, // number
    order: null, // number
    locked: null, // bool
    today: null, // bool
    rating: null, // number?
    contentType: 'dailyBeat', // str
    dashboardV2Header: null, // str?
    infoPageSummary: null, // str
    day: null, // number
    index: null, // number
  }],
  reducers: {
    loadDailyBeats: (state, { payload: dailyBeats }) =>
      dailyBeats.map((dailyBeat, i) => ({
        index: i,
        id: dailyBeat.id,
        type: dailyBeat.type,
        label: dailyBeat.label,
        name: dailyBeat.name,
        subName: dailyBeat.subName,
        firstItem: dailyBeat.firstItem,
        currentItem: dailyBeat.firstItem,
        imageUrl: dailyBeat.imageUrl?.trim(),
        v2ImageUrl: dailyBeat.v2ImageUrl?.trim(),
        description: dailyBeat.description,
        duration: dailyBeat.duration,
        available: dailyBeat.available,
        availableDate: dailyBeat.availableDate,
        completed: dailyBeat.completed,
        percentComplete: dailyBeat.percentComplete,
        order: dailyBeat.order,
        locked: dailyBeat.locked,
        today: dailyBeat.today,
        rating: dailyBeat.rating,
        contentType: 'dailyBeat',
        dashboardV2Header: dailyBeat.dashboardV2Header,
        infoPageSummary: dailyBeat.infoPageSummary,
        day: dailyBeat.startDayOffset + 1,
      })),
    updateDailyBeatComplete: (state, { payload: id }) => {
      const dailyBeat = state.find((db) => db.id === id)
      dailyBeat.completed = true
      dailyBeat.percentComplete = 1
    },
    updateDailyBeatRating: (state, { payload: { id, rating } }) => {
      const dailyBeat = state.find((db) => db.id === id)
      dailyBeat.rating = rating
    },
    updateDailyBeatCurrentItem: (state, { payload: { id, nextItem } }) => {
      const dailyBeat = state.find((db) => db.id === id)
      dailyBeat.currentItem = nextItem
    },
    resetDailyBeat: (state, { payload: { id }}) => {
      const dailyBeat = state.find((db) => db.id === id)
      dailyBeat.currentItem = dailyBeat.firstItem
    },
    completeDailyBeat: (state, { payload: { id } }) => {
      const dailyBeat = state.find((db) => db.id === id)
      dailyBeat.completed = true
      dailyBeat.percentComplete = 1
    },
  },
})

export const {
  loadDailyBeats,
  updateDailyBeatComplete,
  updateDailyBeatRating,
  updateDailyBeatCurrentItem,
  completeDailyBeat,
  resetDailyBeat
} = dailyBeats.actions

export default dailyBeats.reducer
