import { useContext, createContext, useState, useEffect, useMemo } from 'react'
import { useSelector } from 'react-redux'
import { selectUser } from '../reducers/userSlice'
import { COLOR_SCHEME_PREFERENCES } from '../constants/user'
import { isTestEnv } from '../tests/utils'

const ColorSchemeContext = createContext()


export const COLOR_SCHEMES = {
  light: 'light',
  dark: 'dark',
}

export const useColorScheme = () => useContext(ColorSchemeContext)

export const ColorSchemeProvider = ({ children }) => {
  const [colorScheme, setColorScheme] = useState(COLOR_SCHEMES.light)
  const [darkModeQuery, setDarkModeQuary] = useState(null)
  const user = useSelector(selectUser)
  let controller
  // const user = { colorSchemePreference: 'system'}
  const onColorSchemeChange = useMemo(() => {
      return (event) => {
        if (event.matches) {
          setColorScheme(COLOR_SCHEMES.dark)
        } else {
          setColorScheme(COLOR_SCHEMES.light)
        }
      }
    }, [],
  )

  useEffect(() => {
    // This doesnt work in tests
    if (!isTestEnv()) {
      setDarkModeQuary(window.matchMedia('(prefers-color-scheme: dark)'))
    }
  }, [])

  useEffect(() => {
    if (!darkModeQuery) return
    if (user.colorSchemePreference === COLOR_SCHEME_PREFERENCES.SYSTEM) {
      onColorSchemeChange(darkModeQuery)
      darkModeQuery.onchange = onColorSchemeChange
    } else {
      darkModeQuery.onchange = null
      // darkModeQuery.removeEventListener('change', onColorSchemeChange)
      if (user.colorSchemePreference === COLOR_SCHEME_PREFERENCES.LIGHT) {
        setColorScheme(COLOR_SCHEMES.light)
      } else {
        setColorScheme(COLOR_SCHEMES.dark)
      }
    }
  }, [user, darkModeQuery])

  return <ColorSchemeContext.Provider value={colorScheme}>{children}</ColorSchemeContext.Provider>
}
