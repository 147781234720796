export const ImagesToPreload = [
  // Login
  '/static/eye-off.png',
  '/static/eye.png',
  '/static/google-logo.png',
  '/static/apple-icon.png',

  // Teacher Interface
  '/static/close.png',
  '/static/alert-50.png',

  // Student Interface
  '/static/close.png',
  '/static/gray-lock.png',
  '/static/caught-up-check.png',
  '/static/arrow-ios-right.png',
  '/static/shared/focusSeshImages/focusSeshOrientationImages/orientation-slide-1.png',
  '/static/shared/focusSeshImages/focusSeshOrientationImages/orientation-slide-2.png',
  '/static/shared/focusSeshImages/focusSeshOrientationImages/orientation-slide-3.png',
  '/static/shared/focusSeshImages/focusSeshOrientationImages/orientation-slide-4.png',
  '/static/shared/focusSeshImages/focusSeshOrientationImages/orientation-slide-5.png',
  '/static/shared/focusSeshImages/focusSeshOrientationImages/orientation-slide-6.png',
  '/static/lottie/FocusSeshLoading.json',
]
