import {
  getMomentDateTimeInXUnitsFromNow,
  getMomentDateTimeNow
} from "./utils"

const lastDeclinedNewCourseVersionKey = 'lastDeclinedNewCourseVersion'

export const getLastDeclinedNewCourseVersion = () => {
  return localStorage.getItem(lastDeclinedNewCourseVersionKey)
}

export const setLastDeclinedNewCourseVersion = (declinedNewCourseVersion) => {
  localStorage.setItem(lastDeclinedNewCourseVersionKey, declinedNewCourseVersion)
}

export const clearLastDeclinedNewCourseVersion = () => {
  localStorage.removeItem(lastDeclinedNewCourseVersionKey)
}

const appUpsellBottomSheetKey = 'seenAppUpsellBottomSheetWithin24Hours'

export const setSeenAppUpsellBottomSheet = () => {
  const expireTimestamp = getMomentDateTimeInXUnitsFromNow(24, 'hours')
  localStorage.setItem(appUpsellBottomSheetKey, expireTimestamp)
}

export const clearSeenAppUpsellBottomSheet = () => {
  localStorage.removeItem(appUpsellBottomSheetKey)
}

export const getSeenAppUpsellBottomSheet = () => {
  const expireTimestamp = localStorage.getItem(appUpsellBottomSheetKey)
  if (!expireTimestamp) {
    return false
  }

  // compare the expiration time with the current time
  const now = getMomentDateTimeNow()
  if (now > expireTimestamp) {
    // if expired, delete the item from storage and return false
    clearSeenAppUpsellBottomSheet()
    return false
  }
  return true
}
