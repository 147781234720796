export const nativeMessageTypes = {
  login: 'login',
  logout: 'logout',
  goToStudentMode: 'goToStudentMode',
  finishedLoading: 'finishedLoading',
  readyForLogin: 'readyForLogin',
  reloadUser: 'reloadUser',
  updateDailyBeats: 'updateDailyBeats',
  resetStudentMode: 'resetStudentMode',
  playVideo: 'playVideo'
}
