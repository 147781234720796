import { createSlice } from '@reduxjs/toolkit'
import moment from 'moment'

export const selectTeachers = (state) => state.teachers
export const selectTeacher = (id) => (state) => state.teachers.find((teacher) => teacher.id === id)
export const selectPendingTeachers = (state) => state.teachers.filter((teacher) => (teacher.teacherVerificationStatus === TEACHER_VERIFICATION_STATUS.PENDING && teacher.validated))
export const selectRestrictedTeachers = (state) => state.teachers.filter((teacher) => (teacher.teacherVerificationStatus === TEACHER_VERIFICATION_STATUS.KEEP_RESTRICTED || teacher.teacherVerificationStatus === TEACHER_VERIFICATION_STATUS.K8_RESTRICTED)).sort((a, b) => a.id > b.id ? -1 : 1)
export const selectAcceptedTeachers = (state) => state.teachers.filter((teacher) => !!teacher.validated)
export const selectInvitedTeachersForSchool = (schoolId) => (state) => state.teachers.filter((teacher) => !teacher.validated && teacher.schoolId === schoolId)
export const selectAcceptedTeachersForSchool = (schoolId) => (state) => state.teachers.filter((teacher) => !!teacher.validated && teacher.schoolId === schoolId)

export const TEACHER_VERIFICATION_STATUS = {
  PENDING: 'pending',
  K8_RESTRICTED: 'k8_restricted', // this value is deprecated now that K8 educators are allowed access
  KEEP_RESTRICTED: 'keep_restricted',
  APPROVED: 'approved',
  ACCOUNT_CREATED_BEFORE_NEW_PROCESS: 'account_created_before_new_process',
}

const teachersSlice = createSlice({
  name: 'teachers',
  initialState: [
    {
      fullName: null, // string,
      firstName: null, // string,
      lastName: null, // string
      emailAddress: null, // string
      id: null, // number
      schoolName: null, // string,
      validated: null, // boolean,
      validationToken: null, // string,
      invitedDate: null, // string
      schoolState: null, // number
      schoolId: null, // number
      mostRecentCourseStartDate: null, // string
      isQASchool: null, // bool
      teacherVerificationStatus: null, // bool
      jobTitle: null, // bool
      educatorType: null, // bool
      signUpDistrict: null, // bool
      signUpCounty: null, // bool
      signUpState: null, // bool
      signUpCountry: null, // bool
      signUpSchoolName: null, // bool
    },
  ],
  reducers: {
    loadTeachers: (state, {
      payload: teachers,
    }) => teachers.map((teacher) => ({
      fullName: `${teacher.firstName} ${teacher.lastName}`,
      firstName: teacher.firstName,
      lastName: teacher.lastName,
      emailAddress: teacher.email,
      id: teacher.id,
      mostRecentCourseStartDate: !!teacher.mostRecentCourseStartDate ? moment(teacher.mostRecentCourseStartDate).format('YYYY-MM-DD') : '',
      schoolName: teacher.school.name,
      schoolId: teacher.school.id,
      schoolState: teacher.school.state,
      validated: teacher.validated,
      validationToken: teacher.validationToken,
      invitedDate: moment(teacher.createdAt).format('YYYY-MM-DD'),
      isQASchool: teacher.school.isQASchool,
      teacherVerificationStatus: teacher.teacherVerificationStatus,
      jobTitle: teacher.jobTitle,
      educatorType: teacher.educatorType,
      signUpDistrict: teacher.signUpDistrict,
      signUpCounty: teacher.signUpCounty,
      signUpState: teacher.signUpState,
      signUpCountry: teacher.signUpCountry,
      signUpSchoolName: teacher.signUpSchoolName,
    })),
  },
})

export const { loadTeachers } = teachersSlice.actions

export default teachersSlice.reducer
