import { createSlice } from '@reduxjs/toolkit'
import moment from 'moment'

export const selectFocusSeshLogs = (state) => state.focusSeshLogs
export const selectCompletedFocusSeshLogs = (state) => state.focusSeshLogs.filter((log) => log.completed || log.isSyncedSesh)
export const selectCompletedFocusSeshLogsAfterMomentDateTime = (momentDateTime) => (state) => state.focusSeshLogs.filter((log) => log.completed && moment(log.createdAt).isAfter(momentDateTime))

const focusSeshLogsSlice = createSlice({
  name: 'focusSeshLog',
  initialState: [{
    id: null, // number TODO make sure I'm providing this in initial creation
    completed: null, // number
    secondsDuration: null, // str
    checkInTimingLowerLimit: null, // number
    checkInTimingUpperLimit: null, // number
    numCheckIns: null, // str
    avgCheckInResponseValue: null, // float
    createdAt: null, // number
    isSyncedSesh: null, // bool
  }],
  reducers: {
    loadFocusSeshLogs: (state, {
      payload: focusSeshLogs,
    }) => focusSeshLogs,
    addFocusSeshLog: (state, {
      payload: focusSeshLog,
    }) => {
      state.push(focusSeshLog)
    },
    updateFocusSeshLog: (state, { payload: { id, updates } }) => {
      const index = state.findIndex((log) => log.id === id)
      if (index !== -1) {
        state[index] = { ...state[index], ...updates }
      }
    },
    // TODO:
    // 1. Ensure I am either adding or updating focusSeshLog for synced sesh when a user leaves early
    // 2. Ensure I am either adding or updating focusSeshLog for synced sesh when a user completes a synced sesh
  },
})

export const { loadFocusSeshLogs, addFocusSeshLog, updateFocusSeshLog } = focusSeshLogsSlice.actions

export default focusSeshLogsSlice.reducer
