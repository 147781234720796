import { createSlice } from '@reduxjs/toolkit'
import { COLOR_SCHEME_PREFERENCES } from '../constants/user'

export const selectDailyBeatsPreference = (state) => state.user.dailyBeatsPreference
export const selectUser = (state) => state.user
export const selectUserRole = (state) => state.user?.role

export const SEEN_FOCUS_SESH_PRE_SESH_ORIENTATION_STATUS = {
  HAS_NOT_SEEN: 'has_not_seen', // user has not seen the pre-sesh orientation in either application
  NATIVE: 'native', // user has seen the pre-sesh orientation in the native app
  WEB: 'web', // user has seen the pre-sesh orientation in the web app
  BOTH: 'both', // user has seen the pre-sesh orientation in one app and the key differences dialog in the other app
}

export const SEEN_AGE_AND_CONSENT_DISCLAIMER_STATUS = {
  NOT_APPLICABLE: 'not_applicable', // user IS NOT a K8 educator and will never see this modal
  HAS_NOT_SEEN: 'has_not_seen', // user IS a K8 educator and has not yet seen this modal
  HAS_SEEN: 'has_seen', // user IS a K8 educator and has seen this modal
}

const userSlice = createSlice({
  name: 'user',
  initialState: {
    id: null, // number
    firstName: null, // str,
    lastName: null, // str
    emailAddress: null, // str
    role: null, // "student|teacher|personal"
    validated: null, // bool
    deleted: null, // bool
    schoolId: null, // number
    publicId: null, // str: uuid
    dailyBeatsPreference: null, // str
    canManageAdmins: null, // str
    mustAcceptTerms: null, // bool
    summaryOfTermsUpdates: null, // str
    coursePoints: null, // number
    lastSenStudentInterfaceVersion: null, // int

    // Teacher properties
    completedTeacherOrientation: null, // bool
    dismissedStudentViewCallout: null, // bool
    seenWelcomeToStudentView: null, // bool
    teacherVerificationStatus: null, // str
    seenAgeAndConsentDisclaimerStatus: null, // str
    seenImportantInfoAboutNewClass: null, // bool
    seenTenDayCourseTabInfo: null, // bool
    dismissedArchivingClassesCallout: null, // bool

    // Not exactly user properties, but its most convenient
    // to put them here
    totalCoursePoints: null, // number
    pointsPerLessonCompletion: null, // number
    pointsPerDailyBeatCompletion: null, // number
    colorSchemePreference: COLOR_SCHEME_PREFERENCES.SYSTEM, // string

    // Focus Sesh
    seenFocusSeshPreSetupOrientation: null, // bool
    seenFocusSeshPreSeshOrientation: null, // bool
    seenFocusSeshPreSeshOrientationStatus: null, // string
    showCompletionGraph: null // bool
  },
  reducers: {
    loadUser: (state, {
      payload: {
        id,
        classroomId,
        firstName,
        lastName,
        emailAddress,
        role,
        validated,
        deleted,
        schoolId,
        publicId,
        dailyBeatsPreference,
        canManageAdmins,
        mustAcceptTerms,
        summaryOfTermsUpdates,
        completedTeacherOrientation,
        dismissedStudentViewCallout,
        seenWelcomeToStudentView,
        coursePoints,
        totalCoursePoints,
        pointsPerLessonCompletion,
        pointsPerDailyBeatCompletion,
        colorSchemePreference,
        teacherVerificationStatus,
        seenFocusSeshPreSetupOrientation,
        seenFocusSeshPreSeshOrientation,
        seenFocusSeshPreSeshOrientationStatus,
        showCompletionGraph,
        seenAgeAndConsentDisclaimerStatus,
        lastSeenStudentInterfaceVersion,
        seenImportantInfoAboutNewClass,
        seenTenDayCourseTabInfo,
        dismissedArchivingClassesCallout,
      },
    }) => ({
      id,
      classroomId,
      firstName,
      lastName,
      emailAddress,
      role,
      validated,
      deleted,
      schoolId,
      publicId,
      dailyBeatsPreference,
      canManageAdmins,
      mustAcceptTerms,
      summaryOfTermsUpdates,
      completedTeacherOrientation,
      dismissedStudentViewCallout,
      seenWelcomeToStudentView,
      coursePoints,
      totalCoursePoints,
      pointsPerDailyBeatCompletion,
      pointsPerLessonCompletion,
      colorSchemePreference,
      teacherVerificationStatus,
      seenFocusSeshPreSetupOrientation,
      seenFocusSeshPreSeshOrientation,
      seenFocusSeshPreSeshOrientationStatus,
      showCompletionGraph,
      seenAgeAndConsentDisclaimerStatus,
      lastSeenStudentInterfaceVersion,
      seenImportantInfoAboutNewClass,
      seenTenDayCourseTabInfo,
      dismissedArchivingClassesCallout,
    }),
    updateDailyBeatsPreference: (state, { payload: { dailyBeatsPreference } }) => {
      state.dailyBeatsPreference = dailyBeatsPreference
    },
    updateUserName: (state, { payload: { firstName, lastName } }) => {
      state.firstName = firstName
      state.lastName = lastName
    },
    updateUserColorSchemePreference: (state, { payload: { preference } }) => {
      state.colorSchemePreference = preference
    },
    setSeenFocusSeshPreSetupOrientation: (state, { payload: { hasSeen } }) => {
      state.seenFocusSeshPreSetupOrientation = hasSeen
    },
    setSeenFocusSeshPreSeshOrientationStatus: (state, { payload: { status } }) => {
      state.seenFocusSeshPreSeshOrientationStatus = status
    },
    updateShowCompletionGraph: (state, { payload: { showGraph } }) => {
      state.showCompletionGraph = showGraph
    },
    setLastSeenStudentInterfaceVersion: (state, { payload: { version } }) => {
      state.lastSeenStudentInterfaceVersion = version
    },
  },
})

export const { loadUser, updateDailyBeatsPreference, updateUserName, updateUserColorSchemePreference, setSeenFocusSeshPreSeshOrientationStatus, setSeenFocusSeshPreSetupOrientation, updateShowCompletionGraph, setLastSeenStudentInterfaceVersion } = userSlice.actions
export default userSlice.reducer
