import { createSlice } from '@reduxjs/toolkit'

export const selectFetchedStudentDataFromServer = (state) => state.misc.fetchedStudentDataFromServer
export const selectFetchedAdminDataFromServer = (state) => state.misc.fetchedAdminDataFromServer
export const selectFetchedTeacherDataFromServer = (state) => state.misc.fetchedTeacherDataFromServer
export const selectFetchedAdminStudentsFromServer = (state) => state.misc.fetchedAdminStudentsFromServer
export const selectFetchedAdminTeachersFromServer = (state) => state.misc.fetchedAdminTeachersFromServer
export const selectContentfulCourseId = (state) => state.misc.contentfulCourseId

const miscSlice = createSlice({
  name: 'misc',
  initialState: {
    fetchedStudentDataFromServer: false, // boolean
    fetchedTeacherDataFromServer: false, // boolean
    fetchedAdminDataFromServer: false, // boolean
    fetchedAdminStudentsFromServer: false, // boolean,
    fetchedAdminTeachersFromServer: false, // boolean
    contentfulCourseId: null, // str
  },
  reducers: {
    fetchStudentDataComplete: (state) => {
      state.fetchedStudentDataFromServer = true
    },
    fetchStudentDataInProgress: (state) => {
      state.fetchedStudentDataFromServer = false
    },
    fetchTeacherDataComplete: (state) => {
      state.fetchedTeacherDataFromServer = true
    },
    fetchTeacherDataInProgress: (state) => {
      state.fetchedTeacherDataFromServer = false
    },
    fetchAdminDataComplete: (state) => {
      state.fetchedAdminDataFromServer = true
    },
    fetchAdminDataInProgress: (state) => {
      state.fetchedAdminDataFromServer = false
    },
    fetchAdminStudentsComplete: (state) => {
      state.fetchedAdminStudentsFromServer = true
    },
    fetchAdminTeachersCompete: (state) => {
      state.fetchedAdminTeachersFromServer = true
    },
    updateContentfulCourseId: (state, { payload: courseId }) => {
      state.contentfulCourseId = courseId
    },

  },
})

export const {
  fetchStudentDataComplete,
  fetchStudentDataInProgress,
  fetchTeacherDataComplete,
  fetchTeacherDataInProgress,
  fetchAdminDataComplete,
  fetchAdminDataInProgress,
  fetchAdminStudentsComplete,
  fetchAdminTeachersCompete,
  updateContentfulCourseId
} = miscSlice.actions

export default miscSlice.reducer
