import { isProd } from '../utils/utils'

const externalDomain = isProd() ? 'https://www.findingfocus.app' : 'https://beta.findingfocus.app'

const notLoggedInPages = {
  signIn: {
    getRoute: () => '/signIn',
    v1: false,
  },
  studentSignIn: {
    getRoute: () => '/student/sign-in',
    v1: false,
  },
  forgotPassword: {
    getRoute: () => '/forgotPassword',
    v1: false,
  },
  forgotPasswordSuccess: {
    getRoute: () => '/forgotPasswordSuccess',
    v1: false
  },
  studentSignUp: {
    getRoute: () => '/student/sign-up',
    v1: false,
  },
  manageEmailPreferences: {
    getRoute: (token) => `/unsubscribe/preferences${token ? `?token=${token}` : ''}`,
    v1: false
  },
  homeMarketing: {
    getRoute: () => externalDomain,
    external: true,
    v1: true,
  },
  forEducatorsMarketing: {
    getRoute: () => `${externalDomain}/for-educators/`,
    external: true,
    v1: true,
  },
  studentsMarketing: {
    getRoute: () => `${externalDomain}/students/`,
    external: true,
    v1: true,
  },
  appDownloadRedirect: {
    getRoute: () => `/app-download-redirect`,
    v1: false,
  },
  appStore: {
    getRoute: (mobileDeviceType) => {
      if (mobileDeviceType === 'iOS') {
        return notLoggedInPages.iOSAppStore.getRoute()
      } else if (mobileDeviceType === 'Android') {
        return notLoggedInPages.androidAppStore.getRoute()
      }
    },
    external: true,
    v1: true,
  },
  iOSAppStore: {
    getRoute: () => 'https://itunes.apple.com/us/app/evidence-based-courses/id1387307192?mt=8',
    external: true,
    v1: true,
  },
  androidAppStore: {
    getRoute: () => 'https://play.google.com/store/apps/details?id=com.evidencebasedcourses',
    external: true,
    v1: true,
  },
  helpCenter: {
    getRoute: () => 'https://findingfocus.zendesk.com/hc/en-us',
    external: true,
    v1: true,
  },
  contactUs: {
    getRoute: () => 'https://findingfocus.zendesk.com/hc/en-us/requests/new',
    external: true,
    v1: true,
  },
  siteMap: {
    getRoute: () => `${externalDomain}/siteMap`,
    external: true,
    v1: true,
  },
  privacyPolicy: {
    getRoute: () => `${externalDomain}/privacy`,
    external: true,
    v1: true,
  },
  termsOfUse: {
    getRoute: () => `${externalDomain}/terms`,
    external: true,
    v1: true,
  },

}

export default notLoggedInPages
