import { createSlice } from '@reduxjs/toolkit'

export const selectFocusSeshPresets = (state) => state.focusSeshPresets

const focusSeshPresetsSlice = createSlice({
  name: 'focusSeshPresets',
  initialState: [
    // {
    //   isTimed: true, // bool,
    //   secondsDuration: null, // str,
    //   checkInTiming: null, // str
    //   checkInChime: 'Chime', // str,
    //   startAndEndChime: 'Chime', // str
    // },
  ],
  reducers: {
    loadFocusSeshPresets: (state, {
      payload: focusSeshPresets,
    }) => focusSeshPresets,
    addPreset: (state, {
      payload: focusSeshPreset,
    }) => [focusSeshPreset, ...state],
    deletePreset: (state, {
      payload: deletingPresetIndex,
    }) => {
      if (deletingPresetIndex < 0 || deletingPresetIndex >= state.length) {
        console.error('Error deleting preset. Index out of bounds. Index provided:', deletingPresetIndex)
        return state
      }
      state.splice(deletingPresetIndex, 1)
    },
  },
})

export const { loadFocusSeshPresets, addPreset, deletePreset } = focusSeshPresetsSlice.actions

export default focusSeshPresetsSlice.reducer
