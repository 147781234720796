import { createTheme } from '@mui/material'
import { COLOR_SCHEMES, useColorScheme } from '../contexts/ColorSchemeContext'

export const useStudentThemeWithColorScheme = () => {
  const colorScheme = useColorScheme()
  return getStudentTheme(colorScheme === COLOR_SCHEMES.light)
}

let getStudentTheme = (lightMode) => {
  let studentTheme = createTheme({
    palette: {
      fontColor: lightMode ? '#000' : '#FFF',
      outlineBoxBorder: lightMode ? '#d9d9d9' : 'none',
      background: {
        main: lightMode ? '#FAFAFA' : '#191A28',
        tileBackgroundColor: lightMode ? '#FFFFFF' : '#232538',
        darker: '#242532',
        gradientStart: '#333447',
        gradientEnd: '#656686',
        player: '#020101',
      },
      navigationDrawer: {
        activeTabBGColor: 'rgba(255, 255, 255, 0.10)',
        tabHoverColor: 'rgba(255, 255, 255, .05)',
        tabRippleColor: 'rgba(125, 137, 159, 0.25)',
        dividerColor: '1px solid rgba(255, 255, 255, 0.87)',

      },
      mainText: {
        main: lightMode ? '#000000' : '#FFFFFF',
        dark: 'black',
      },
      status: {
        activeHighlight: '#FDC72A',
        success: '#04BC70',
        lighterError: '#F98686',
        error: '#FF6E56',
        slightlyDarkerError: '#FF5656',
        darkerError: '#CA2626',
        inactive: '#D8D8D8'
      },
      picker: {
        selectedBackgroundColor: lightMode ? 'rgba(0, 0, 0, .2)' : 'rgba(255, 255, 255, .1)',
        hoverBackgroundColor: lightMode ? 'rgba(0, 0, 0, .1)' : 'rgba(0, 0, 0, .5)',
      },
      profile: {
        profileOptionBorderColor: '#666770'
      },
      textField: {
        backgroundColor: 'rgba(150,150,150,0.16)',
        textColor: '#555555',
        borderWidth: '0'
      },
      opaqueButton: {
        backgroundColor: lightMode ? '#76BFFE' : '#76BFFE',
        textColor: 'rgba(255, 255, 255, 0.87)',
        hoverColor: 'rgba(118,191,254,0.47)'
      },
      transparentButton: {
        borderColor: lightMode ? '#000000' : '#ffffff'
      },
      selectionPill: {
        borderColor: '#CECECE',
        active: '#DDDDDD'
      },
      lightActiveBlueColor: '#C1E0FB',
      activeBlueColor: '#76BFFE',
      darkerActiveBlueColor: '#10A5FC',
      inactiveHexagonColor: '#7c7c80',
      playerTextColor: 'rgba(255, 255, 255, 0.87)',
      playerTextColorLine2: 'rgba(255, 255, 255, 0.7)',
      fontFamily: 'Montserrat, sans-serif',
      lessonPlayerFadeSpeed: 400,
      activeSubmitButtonShadowColor: '#10A5FC',
      inactiveGrayPlayerColor: '#979797',
      questionMultiOptionInactiveBorderColor: '#737373',
      iconBrightnessFilter: '100%',
      grayBorder: '#D8D8D8',
      grayTransparentBorder: 'rgba(216,216,216,0.35)',
      tipCardBlue: '#484866',
      copyButtonBGColor: 'rgba(216,216,216,0.38)',
      darkBlueForLink: '#3E4F89',
      classRoster: {
        titleColor: '#ffffff',
      },
      dialog: {
        backgroundColor: '#242532',
        strokeGradient: 'linear-gradient(to right, #76BFFE, #60C4C8)'
      },
      boxWithShadowBackgroundColor: '#242532',
      linkColor: '#76BFFE',
      grayTextColor: '#979797',
      lessonSummaryLabelBackgroundColor: '#ffffff',
      lessonSummaryLabelFontColor: '#000000',
      questionMultiWithImageCardBackgroundColor: '#333334',
      questionMultiOptionTextColor: 'rgba(255, 255, 255, 0.87)',
      player: {
        incorrectAnswerChoice: '#EE5555',
        incorrectFeedbackModalContinueButtonShadow: '#CB3E3E',
        incorrectFeedbackModalBackground: '#202121',
        correctFeedbackModalTextColor: '#000000',
        disabledPlayerSubmitButtonFontColor: '#ffffff'
      },
      dashboardV2: {
        progressMenu: {
          progressLabelColor: lightMode ? '#EAEBED' : '#EAEBED',
          progressLabelTextColor: lightMode ? '#000000' : '#000000',
        },
        headerButton: {
          iconFillColor: '#76BFFE',
          backgroundColor: lightMode ? '#FBFBFB' : '#2C2E41',
          boxShadow: lightMode ? '0px 1px 2px 0px rgba(0,0,0,0.25)' : '',
          border: lightMode ? '0px' : '1px solid #333448',
          textColor: lightMode ? '#000' : '#FFF'
        },
        button: {
          buttonBackgroundColor: '#76BFFE',
          buttonBottomBorderColor: '#10A5FC',
          textColor: lightMode ? '#FFFFFF' : '#000000',
        },
        progressPopup: {
          borderColor: lightMode ? 'rgba(0,0,0,0.25)' : 'rgba(255,255,255,0.25)',
          boxShadow:`0 1px 8px 2px rgba(0, 0, 0, ${lightMode ? '0.15' : '0.5'})`
        },
        content: {
          itemDescriptionTextColor: lightMode ? 'rgba(0,0,0,0.65)' : 'rgba(255,255,255,0.85)',
          itemDetailBorderColor: lightMode ? 'rgba(0,0,0,0.15)' : 'rgba(255,255,255,0.39)',
          nextUpLabelText: '#000000',
        },
        progressBar: {
          unfilledBackgroundColor: '#D8D8D8',
          unfilledInnerShadowValue: '0px 0px 2px 1px rgba(0, 0, 0, 0.25) inset',
          filledColor: '#76BFFE',
          filledBorderColor: '#47A0ED',

        },
      },
      leftTabs: {
        backgroundColor: '#232538',
        textColor: 'rgba(255,255,255,0.84)',
      },
      tileShadow: {
        boxShadow: '0 1px 3px rgba(0, 0, 0, 0.16)'
      },
      selectRoleDialog: {
        optionBoxShadow: '0 2px 3px rgba(0, 0, 0, 0.3)'
      },
      videoLibrary: {
        cardBackgroundColor: lightMode ? '#FFFFFF' : '#232538',
        playlistCountBackgroundColor: '#76BFFE',
        cardFontColor: lightMode ? '#000000' : '#FFFFFF',
        headerFontColor: lightMode ? '#000000' : '#FFFFFF',
        drilldownLockIconColor: lightMode ? '#000000' : '#FFFFFF',
        drilldownLessonDescriptionText: lightMode ? '#000000' : '#FFFFFF',
        numVideosCountColor: '#FFFFFF',
        drilldownLockColor: 'rgba(255,255,255,0.78)',
        drilldownHighlightTileLockedShadeColor: lightMode ? 'rgba(0, 0, 0, 0.4)' : 'rgba(0, 0, 0, 0.7)'
      },
      focusPreview: {
        cardBackgroundColor: lightMode ? '#FFFFFF' : '#232538',
        cardBorder: lightMode ? '' : '2px solid #353648',
        cardBoxShadow: lightMode ? '0 1px 5px rgba(0, 0, 0, 0.16)' : '',
      },
      deleteAccount: {
        areYouSureBackgroundColor: lightMode ? '#FFFFFF' : '#232538',
        borderColor: lightMode ? '#000000' : '#353648',
      },
      completionPage: {
        statisticsBorderColor: lightMode ? 'rgba(0, 0, 0, .5)' : 'rgba(255, 255, 255, .5)',
        explainerFontColor: '#000000',
        pointsEarnedColor: '#FDC72A',
        courseProgressColor: '#74BBF9',
        pointsEarnedFontColor: lightMode ? '#000000' : '#FDC72A',
        courseProgressFontColor: lightMode ? '#000000' : '#74BBF9',
      },
      fourDigitValidationInput: {
        backgroundColor: lightMode ? '#F3F3F3' : '#353642',
        disabledBackgroundColor: lightMode ? '#76BFFE' : '#76BFFE',
        textColor: lightMode ? '#000000' : '#FFFFFF',
        borderColor: lightMode ? '#A0A0A0' : '#9E9E9E',
        focusedBorderColor: lightMode ? '#000000' : '#FFFFFF',
        hasValueBorderColor: lightMode ? '#76BFFE' : '#76BFFE',
      }
    },
  })


  studentTheme = createTypographyFromTheme(studentTheme)
  return studentTheme
}

export const createTypographyFromTheme = (theme) => {
  return createTheme(theme, {
    typography: {
      // Nav link is a temporary type while we transition to V2.
      navLink: {
        fontSize: '15px',
        color: theme.palette.leftTabs.textColor,
        fontFamily: 'Montserrat, sans-serif',
        fontWeight: '500',
      },
      h2: {
        fontSize: '42px',
        color: theme.palette.mainText.main,
        fontFamily: 'Montserrat, sans-serif',
        fontWeight: '600',
        letterSpacing: '1px',
        [theme.breakpoints.down('sm')]: {
          fontSize: '35px'
        }
      },
      h3: {
        fontSize: '32px',
        color: theme.palette.mainText.main,
        fontFamily: 'Montserrat, sans-serif',
        fontWeight: '500',
        letterSpacing: '.76px',
        [theme.breakpoints.down('sm')]: {
          fontSize: '30px'
        }
      },
      h4: {
        fontSize: '32px',
        color: theme.palette.mainText.main,
        fontFamily: 'Montserrat, sans-serif',
        fontWeight: '500',
        letterSpacing: '0px',
        [theme.breakpoints.down('sm')]: {
          fontSize: '30px'
        }
      },
      h5: {
        fontSize: '24px',
        color: theme.palette.mainText.main,
        fontFamily: 'Montserrat, sans-serif',
        fontWeight: '600',
        letterSpacing: '0.57px',
        lineHeight: 'normal',
        [theme.breakpoints.down('sm')]: {
          fontSize: '20px'
        }
      },
      h6: {
        fontSize: '18px',
        color: theme.palette.mainText.main,
        fontFamily: 'Montserrat, sans-serif',
        fontWeight: '500',
        letterSpacing: '0.43px',
        margin: '0px',
        lineHeight: '1.44',
        [theme.breakpoints.down('sm')]: {
          fontSize: '16px'
        }
      },
      subtitle1: {
        fontSize: '24px',
        color: theme.palette.mainText.main,
        fontFamily: 'Montserrat, sans-serif',
        fontWeight: '400',
        letterSpacing: '1.2px',
        [theme.breakpoints.down('sm')]: {
          fontSize: '20px'
        }
      },
      subtitle2: {
        fontSize: '22px',
        color: theme.palette.mainText.main,
        fontFamily: 'Montserrat, sans-serif',
        fontWeight: '400',
        letterSpacing: '1px',
        [theme.breakpoints.down('sm')]: {
          fontSize: '20px'
        }
      },
      body1: {
        fontSize: '16px',
        color: theme.palette.mainText.main,
        fontFamily: 'Montserrat, sans-serif',
        fontWeight: '500',
        letterSpacing: '0px',
        [theme.breakpoints.down('sm')]: {
          fontSize: '13px',
          lineHeight: '1.2'
        }
      },
      body2: {
        fontSize: '14px',
        color: theme.palette.mainText.main,
        fontFamily: 'Montserrat, sans-serif',
        fontWeight: '400',
        letterSpacing: '0px',
        lineHeight: 'normal',
        [theme.breakpoints.down('sm')]: {
          fontSize: '12px',
          lineHeight: 'normal'
        }
      },
      body3: {
        fontSize: '12px',
        color: theme.palette.mainText.main,
        fontFamily: 'Montserrat, sans-serif',
        fontWeight: '300',
        letterSpacing: '0px',
        lineHeight: 'normal',
        [theme.breakpoints.down('sm')]: {
          fontSize: '10px',
          lineHeight: 'normal'
        }
      },
      button: {
        fontSize: '18px',
        color: theme.palette.mainText.main,
        fontFamily: 'Montserrat, sans-serif',
        fontWeight: '700',
        letterSpacing: '0.81px',
        [theme.breakpoints.down('sm')]: {
          fontSize: '16px'
        }
      },
      overline: {
        fontSize: '10px',
        color: theme.palette.mainText.main,
        fontFamily: 'Montserrat, sans-serif',
        fontWeight: '400',
        letterSpacing: '0px',
        textTransform: 'none',
        [theme.breakpoints.down('sm')]: {
          fontSize: '10px'
        }
      }
    }
  })
}
export default getStudentTheme(true)
