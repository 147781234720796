import App from 'pages/_app'
import { render } from '@testing-library/react'
import {
  getDailyBeats,
  getDetailedLesson, getFocusSeshLogs, getFocusSeshPresets, getInitialDailyBeats,
  getInitialLessons, getNextDailyBeatItem,
  getUser,
  postUpdateLessonProgress,
} from '../requests/studentRequests'
import testUserLessonsPost from './testData/userLessonsPost/responseWithoutAnswerItem.json'
import {
  getAllClassrooms,
  getContentfulCourseId,
  getLessonSummaries,
  getMostRecentFocusSeshDataForEachStudentInClassroom,
  getStudentsForSchool,
} from '../requests/teacherRequests'
import config from '../config'
import testLessons from './testData/lessons/lessonOneStartedNoneComplete.json'
import testDailyBeats from './testData/dailyBeats/noCompleteDailyBeats.json'
import testNextDailyBeat from './testData/nextDailyBeatItem/nextDailyBeatCard.json'
import testDetailedLesson from './testData/lessonDetails/lessonDetailsCardTextAndImage.json'
import testLessonSummaries from './testData/lessonSummaries/testLessonSummaries.json'


export const isTestEnv = () => {
  return process.env.NODE_ENV === 'test'
}

export const renderInApp = (component, pageProps={}) => {
  return render(<App Component={component} pageProps={pageProps}/>)
}

export const mockRouterPath = (pathname, query = {}, backMock = jest.fn(), pushMock = jest.fn()) => {
  const useRouter = jest.spyOn(require('next/router'), 'useRouter')
  useRouter.mockImplementation(() => ({
    asPath: pathname,
    pathname,
    query,
    back: backMock,
    push: pushMock,
  }))
}

export const mockRequests = (
  {
    testLessons = require('tests/testData/lessons/lessonOneStartedNoneComplete.json'),
    testDailyBeats = require('tests/testData/dailyBeats/noCompleteDailyBeats.json'),
    testNextDailyBeat = require('tests/testData/nextDailyBeatItem/nextDailyBeatCard.json'),
    testUser = require('tests/testData/users/testUserResponse.json'),
    testDetailedLesson = require('tests/testData/lessonDetails/lessonDetailsCardTextAndImage.json'),
    testLessonSummaries = require('tests/testData/lessonSummaries/testLessonSummaries.json'),
    testUserLessonsPost = require('tests/testData/userLessonsPost/responseWithoutAnswerItem.json'),
    focusSeshPresets = require('tests/testData/focusSeshPresets/populatedPresets.json'),
    focusSeshLogs = [],
  }) => {
  getInitialLessons.mockImplementation(async () => testLessons)
  getInitialDailyBeats.mockImplementation(async () => testDailyBeats)
  getNextDailyBeatItem.mockImplementation(async () => testNextDailyBeat)
  getUser.mockImplementation(async () => testUser)
  getDetailedLesson.mockImplementation(async () => testDetailedLesson)
  postUpdateLessonProgress.mockImplementation(async () => testUserLessonsPost)
  getLessonSummaries.mockImplementation(async () => testLessonSummaries)
  getContentfulCourseId.mockImplementation(async () => 'courseId')
  getFocusSeshPresets.mockImplementation(async () => focusSeshPresets)
  getFocusSeshLogs.mockImplementation(async () => focusSeshLogs)
}

export const mockTeacherRequests = (
  {
    testClassrooms = require('tests/testData/classrooms/testClassrooms.json'),
    testLessonSummaries = require('tests/testData/lessonSummaries/testLessonSummaries.json'),
    testStudents = require('tests/testData/students/testStudentsResponse.json'),
    testTeacher = require('tests/testData/users/testUserResponseTeacher-Approved.json'),
    testLessons = require('tests/testData/lessons/lessonOneStartedNoneComplete.json'),
    testDailyBeats = require('tests/testData/dailyBeats/noCompleteDailyBeats.json'),
    testNextDailyBeat = require('tests/testData/nextDailyBeatItem/nextDailyBeatCard.json'),
    testDetailedLesson = require('tests/testData/lessonDetails/lessonDetailsCardTextAndImage.json'),
    testUserLessonsPost = require('tests/testData/userLessonsPost/responseWithoutAnswerItem.json'),
    mostRecentFocusSeshDataForEachStudent = require('tests/testData/mostRecentFocusSeshDataForEachStudent/classroomId-5446-allStudentsInactive.json')
  }) => {
  getAllClassrooms.mockImplementation(async () => testClassrooms)
  getLessonSummaries.mockImplementation(async () => testLessonSummaries)
  getUser.mockImplementation(async () => testTeacher)
  getStudentsForSchool.mockImplementation(async () => testStudents)
  getContentfulCourseId.mockImplementation(async () => 'courseId')

  getInitialLessons.mockImplementation(async () => testLessons)
  getInitialDailyBeats.mockImplementation(async () => testDailyBeats)
  getNextDailyBeatItem.mockImplementation(async () => testNextDailyBeat)
  getDetailedLesson.mockImplementation(async () => testDetailedLesson)
  postUpdateLessonProgress.mockImplementation(async () => testUserLessonsPost)
  getLessonSummaries.mockImplementation(async () => testLessonSummaries)
  getMostRecentFocusSeshDataForEachStudentInClassroom.mockImplementation(async () => mostRecentFocusSeshDataForEachStudent)
}
