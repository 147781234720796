import { createSlice } from '@reduxjs/toolkit'

export const selectStudentsByClassroomId = (classroomId) => (state) => state.students.filter((student) => student.classroomId === classroomId)
export const selectStudentById = (studentId) => (state) => state.students.find((student) => student.id === studentId)

const exampleStudents = [
  {
    id: 'example-upcoming-1',
    firstName: 'David',
    lastName: 'Dobrik',
    validated: false,
    hasLoggedInToNativeApp: false,
    classroomId: 'example-upcoming-class',
    lessons: ['completed', 'not started', 'not started', 'not started'],
    dailyBeats: [
      'not started',
      'not started',
      'not started',
      'not started',
      'not started',
      'not started',
    ],
    emailAddress: 'daviddobrik@gmail.com',
    validationToken: null,
    exampleStudent: true,
    coursePoints: 10,
    totalCoursePoints: 100,
  },
  {
    id: 'example-upcoming-2',
    firstName: 'Olivia',
    lastName: 'Rodrigo',
    validated: false,
    hasLoggedInToNativeApp: true,
    classroomId: 'example-upcoming-class',
    lessons: [],
    dailyBeats: [],
    emailAddress: 'oliviarodrigo@gmail.com',
    validationToken: null,
    exampleStudent: true,
    coursePoints: 0,
    totalCoursePoints: 100,
  },
  {
    id: 'example-upcoming-3',
    firstName: 'Kendall',
    lastName: 'Jenner',
    validated: true,
    hasLoggedInToNativeApp: true,
    classroomId: 'example-upcoming-class',
    lessons: [],
    dailyBeats: [],
    emailAddress: 'kendalljenner@gmail.com',
    validationToken: null,
    exampleStudent: true,
    coursePoints: 0,
    totalCoursePoints: 100,
  },
  {
    id: 'example-upcoming-4',
    firstName: 'Lebron',
    lastName: 'James',
    validated: true,
    hasLoggedInToNativeApp: false,
    classroomId: 'example-upcoming-class',
    lessons: [],
    dailyBeats: [],
    emailAddress: 'lebronjames@gmail.com',
    validationToken: null,
    exampleStudent: true,
    coursePoints: 0,
    totalCoursePoints: 100,
  },
  {
    id: 'example-active-1',
    firstName: 'Student',
    lastName: 'A',
    validated: true,
    hasLoggedInToNativeApp: true,
    classroomId: 'example-active-class',
    lessons: ['completed', 'in progress', 'not started', 'not started'],
    dailyBeats: [
      'completed',
      'completed',
      'not started',
      'not started',
      'not started',
      'not started',
    ],
    emailAddress: 'studenta@gmail.com',
    validationToken: null,
    exampleStudent: true,
    coursePoints: 30,
    totalCoursePoints: 100,
  },
  {
    id: 'example-active-2',
    firstName: 'Student',
    lastName: 'B',
    validated: true,
    hasLoggedInToNativeApp: true,
    classroomId: 'example-active-class',
    lessons: ['completed', 'completed', 'not started', 'not started'],
    dailyBeats: [
      'completed',
      'completed',
      'completed',
      'not started',
      'not started',
      'not started',
    ],
    emailAddress: 'studentb@gmail.com',
    validationToken: null,
    exampleStudent: true,
    coursePoints: 50,
    totalCoursePoints: 100,
  },
  {
    id: 'example-active-3',
    firstName: 'Student',
    lastName: 'C',
    validated: true,
    hasLoggedInToNativeApp: true,
    classroomId: 'example-active-class',
    lessons: ['completed', 'completed', 'not started', 'not started'],
    dailyBeats: [
      'completed',
      'completed',
      'completed',
      'not started',
      'not started',
      'not started',
    ],
    emailAddress: 'studentc@gmail.com',
    validationToken: null,
    exampleStudent: true,
    coursePoints: 60,
    totalCoursePoints: 100,
  },
  {
    id: 'example-active-4',
    firstName: 'Student',
    lastName: 'D',
    validated: true,
    hasLoggedInToNativeApp: true,
    classroomId: 'example-active-class',
    lessons: ['completed', 'not started', 'not started', 'not started'],
    dailyBeats: [
      'not started',
      'not started',
      'not started',
      'not started',
      'not started',
      'not started',
    ],
    emailAddress: 'studentd@gmail.com',
    validationToken: null,
    exampleStudent: true,
    coursePoints: 10,
    totalCoursePoints: 100,
  },
]

const studentsSlice = createSlice({
  name: 'students',
  initialState: [
  ],
  reducers: {
    loadStudents: (state, {
      payload: students,
    }) => {
      const mappedStudents = students.map((student) => ({
        id: student.id,
        firstName: student.firstName,
        lastName: student.lastName,
        validated: student.validated,
        hasLoggedInToNativeApp: student.hasLoggedInToNativeApp,
        classroomId: student.classroomId,
        lessons: student.lessons,
        dailyBeats: student.dailyBeats,
        emailAddress: student.emailAddress,
        validationToken: student.validationToken,
        exampleStudent: false,
        coursePoints: student.coursePoints,
        totalCoursePoints: student.totalCoursePoints,
      }))
      return exampleStudents.concat(mappedStudents)
    }
  },
})

export const { loadStudents } = studentsSlice.actions

export default studentsSlice.reducer
