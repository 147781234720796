import { createSlice } from '@reduxjs/toolkit'

export const selectLessonSummary = (lessonId) => (state) => {
  return state.lessonSummaries.find((ls) => ls.id === lessonId)
}

export const selectLessonSummaries = (state) => state.lessonSummaries

export const selectVideoHighlight = (lessonId, videoHighlightId) => (state) => {
  return state
    .lessonSummaries?.find((ls) => ls.id === lessonId)?.highlights
    .videoHighlights
    .find((vh) => vh.id === videoHighlightId)
}

const lessonSummariesSlice = createSlice({
  name: 'lessonSummaries',
  initialState: [],
  /* initialState is an array of objects with the following values:
  {
    highlights: {
      label,
      lessonPreSummary,
      teacherLessonImage,
      videoHighlights: [
        id,
        name,
        imageUrl,
        url,
        videoLength
      ]
    },
    id,
    label,
    name,
    summary,
    description,
    v2ImageUrl,
   */
  reducers: {
    loadLessonSummaries: (state, {
      payload: lessonSummaries,
    }) =>
      lessonSummaries.map((lessonSummary) => ({
        highlights: {
          label: lessonSummary.highlights.label,
          lessonPreSummary: lessonSummary.highlights.lessonPreSummary,
          teacherLessonImage: lessonSummary.highlights.teacherLessonImage,
          videoHighlights: lessonSummary.highlights.videoHighlights.map((highlight) => ({
            name: highlight.name,
            imageUrl: highlight.imageUrl,
            url: highlight.url,
            videoLength: highlight.videoLength,
            id: highlight.id,
            externalUrl: highlight.externalUrl,
            spanishCc: highlight.spanishCc,
            englishCc: highlight.englishCc,
          })),
        },
        id: lessonSummary.id,
        label: lessonSummary.label,
        name: lessonSummary.name,
        summary: lessonSummary.summary,
        description: lessonSummary.description,
        v2ImageUrl: lessonSummary.v2ImageUrl,
      })),
  },
})

export const { loadLessonSummaries } = lessonSummariesSlice.actions

export default lessonSummariesSlice.reducer
