import { createSlice } from '@reduxjs/toolkit'
import moment from 'moment'
import { getMomentDateNow } from '../../utils/utils'

export const lessonIsAvailable = (lesson) => moment(lesson.availableDate).format(moment.HTML5_FMT.DATE) <= getMomentDateNow()

export const selectFinalLessonDate = (state) => state.lessons[state.lessons.length - 1].availableDate

export const selectHasCompletedContent = (state) => {
  // eslint-disable-next-line no-restricted-syntax
  for (const lesson of state.lessons) {
    if (lesson.completed) return true
  }
  // eslint-disable-next-line no-restricted-syntax
  for (const dailyBeat of state.dailyBeats) {
    if (dailyBeat.completed) return true
  }
  return false
}

export const selectNextAvailableLesson = (state) => {
  let lesson
  // eslint-disable-next-line no-restricted-syntax
  for (lesson of state.lessons) {
    if (!lesson.completed && lessonIsAvailable(lesson)) return lesson
  }
  // No lesson is available
  return null
}

export const selectNextUnavailableLesson = (state) => {
  // eslint-disable-next-line no-restricted-syntax
  for (const lesson of state.lessons) {
    if (!lessonIsAvailable(lesson)) return lesson
  }
  // All lessons are available
  return null
}

export const selectLessonById = (id) => (state) => state.lessons.find((lesson) => id === lesson.id)

export const selectLessons = (state) => state.lessons

export const selectNumCompletedLessons = (state) => state.lessons.filter((lesson) => lesson.completed).length

export const selectComputedPercentComplete = (id) => (state) => {
  const lesson = state.lessons.find((lesson) => id === lesson.id)

  if (!lesson) {
    return 0
  }

  const {
    totalNumItems,
    totalItemIndex,
  } = lesson

  return totalItemIndex / totalNumItems
}

const lessonsSlice = createSlice({
  name: 'lessons',
  initialState: [
    {
      // Loaded on /userLessons/{CLASSROOM_ID}/lessons request
      availableDate: null, // str: timestamp,
      completed: null, // bool,
      id: null, // str,
      label: null, // str
      subName: null, // str
      imageUrl: null, // str
      v2ImageUrl: null, // str
      addtImageUrl: null, // str
      lessonName: null, // str
      lessonDescription: null, // str
      duration: null, // number
      locked: null, // bool
      percentComplete: null, // number
      index: null, // number

      // Loaded on /userLessons/{CLASSROOM_ID}/lessons/{LESSON_ID} request
      sectionId: null, // str
      sectionIndex: null, // number
      numSections: null, // number
      itemIndex: null, // number
      numItems: null, // number
      sectionLabel: null, // str
      currentItem: null, // obj
      currentAnswerItem: null, // obj?
      previousAnswerId: null, // str?
      userAnswer: null, // str?
      contentType: 'lesson', // str
      dashboardV2Header: null, // str?
      infoPageSummary: null, // str
      digestSummary: null, // str
      day: null, // number
    },
  ],
  reducers: {
    loadInitialLessonData: (state, { payload: lessons }) =>
      lessons.map((lesson, i) => ({
        index: i,
        availableDate: lesson.availableDate,
        completed: lesson.completed,
        id: lesson.id,
        label: lesson.label,
        subName: lesson.subName,
        imageUrl: lesson.imageUrl.trim(),
        v2ImageUrl: lesson.v2ImageUrl?.trim(),
        addtImageUrl: lesson.addtImageUrl.trim(),
        lessonName: lesson.lessonName,
        lessonDescription: lesson.lessonDescription,
        duration: lesson.duration,
        locked: lesson.locked,
        percentComplete: lesson.percentComplete,
        dashboardV2Header: lesson.dashboardV2Header,
        contentType: 'lesson',
        infoPageSummary: lesson.infoPageSummary,
        digestSummary: lesson.digestSummary,
        day: lesson.startDayOffset + 1,
      })),
    loadDetailedLessonData: (state, { payload: lesson }) => {
      lesson.classroomMode = lesson.classroomMode || false
      const lessonInState = state.find((l) => l.id === lesson.lessonId)
      lessonInState.sectionId = lesson.sectionId
      lessonInState.sectionIndex = lesson.sectionIndex
      lessonInState.numSections = lesson.numSections
      lessonInState.itemIndex = lesson.itemIndex
      lessonInState.numItems = lesson.numItems
      lessonInState.sectionLabel = lesson.sectionLabel
      lessonInState.previousAnswerId = lesson.answerId
      lessonInState.currentItem = lesson.item
      lessonInState.currentAnswerItem = lesson.answerItem
      lessonInState.userAnswer = lesson.classroomMode ? undefined : lesson.userAnswer
      // Note: The backend returns a percentComplete field, which we ignore, because it uses
      // an old way of calculating percent complete based on sections and items. We just
      // use total items.
      lessonInState.percentComplete = lesson.lessonCompleted === true ? 1 : lesson.totalItemIndex / lesson.totalNumItems
      lessonInState.totalItemIndex = lesson.totalItemIndex
      lessonInState.totalNumItems = lesson.totalNumItems
    },
    updatePercentComplete: (state, { payload: { lessonId, percentComplete } }) => {
      const lessonInState = state.find((l) => l.id === lessonId)
      lessonInState.percentComplete = percentComplete
      if (percentComplete === 1) {
        lessonInState.completed = true
      }
    },
  },
})

export const { loadInitialLessonData, loadDetailedLessonData, updatePercentComplete } = lessonsSlice.actions

export default lessonsSlice.reducer
