const studentPages = {
  index: {
    route: '/',
    v1: false,
  },
  dashboard: {
    route: '/portal/dashboard',
    v1: false,
  },
  lessons: {
    route: '/portal/lessons',
    v1: false,
  },
  dailyBeats: {
    route: '/portal/beats',
    v1: false,
  },
  focus: {
    route: '/portal/focus',
    v1: false,
  },
  focusPreview: {
    route: '/portal/focus/preview',
    v1: false,
  },
  focusSeshSetup: {
    route: '/portal/focus/setup',
    v1: false,
  },
  focusSeshPresets: {
    route: '/portal/focus/presets',
    v1: false,
  },
  focusSeshAddNewPreset: {
    route: '/portal/focus/add-new-preset',
    v1: false,
  },
  focusSeshPlayer: {
    route: '/portal/focus/player',
    v1: false,
  },
  videoLibrary: {
    route: '/portal/video-library',
    v1: false,
  },
  videoLibraryDrilldown: {
    getRoute: (lessonSummaryId) => `/portal/video-library/${lessonSummaryId}`,
    v1: false,
  },
  videoHighlight: {
    getRoute: (lessonId, videoHighlightId) => `/portal/video-library/${lessonId}/${videoHighlightId}`,
    v1: false,
  },
  profile: {
    route: '/portal/profile',
    v1: false,
  },
  joinAnotherClass: {
    getRoute: () => '/portal/profile/join-another-class',
    v1: false,
  },
  accountSettings: {
    getRoute: () => '/portal/profile/account-settings',
    v1: false,
  },
  updateName: {
    getRoute: () => '/portal/profile/update-name',
    v1: false,
  },
  updatePassword: {
    getRoute: () => '/portal/profile/update-password',
    v1: false,
  },
  deleteAccount: {
    getRoute: () => '/portal/profile/delete-account',
    v1: false,
  },
  deleteAccountConfirm: {
    getRoute: () => '/portal/profile/delete-account-confirm',
    v1: false,
  },
  resetStudentViewConfirm: {
    getRoute: () => '/portal/profile/reset-student-view-confirm',
    v1: false,
  },
  lessonPlayer: {
    getRoute: (lessonId) => `/portal/lesson/${lessonId}`,
    v1: false,
  },
  dailyBeatPlayer: {
    getRoute: (dailyBeatId) => `/portal/daily/${dailyBeatId}`,
    v1: false,
  },
}

export const defaultStudentPage = studentPages.dashboard

export default studentPages

// We used to use this function for navigation when we needed to
// navigate between the V1 and V2 servers. Now it is just a proxy
// for router.push()
export const navigationPush = (url, router, v1 = false, params = {}, shallow = false) => {
  const paramsString = Object.keys(params).reduce((string, key) => {
    return !string ? `${key}=${params[key]}` : `${string}&${key}=${params[key]}`
  }, '')
  if (v1) {
    window.location.href = `${url}${!!paramsString ? `?${paramsString}` : ''}`
  } else {
    router.push(`${url}${!!paramsString ? `?${paramsString}` : ''}`, undefined, { shallow: shallow })
  }
}

export const shallowQueryParamUpdate = (router, params) => {
  const updatedQuery = { ...router.query }

  // Update query parameters based on the params object
  Object.keys(params).forEach(key => {
    if (params[key] === null) {
      delete updatedQuery[key]
    } else {
      updatedQuery[key] = params[key]
    }
  })

  router.replace({ pathname: router.pathname, query: updatedQuery }, undefined, { shallow: true })
}
