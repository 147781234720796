import store, { ROOT_ACTIONS } from '../reducers/store'
import studentPages, { navigationPush } from '../navigation/studentPages'
import { postMessageToNativeApp } from '../utils/utils'
import { nativeMessageTypes } from '../constants/nativeMessageTypes'

const logout = (router) => {
  localStorage.removeItem('access_token')
  const { dispatch } = store
  dispatch(ROOT_ACTIONS.RESET)
  postMessageToNativeApp({
    type: nativeMessageTypes.logout,
  })
  navigationPush(studentPages.index.route, router, studentPages.index.v1)
}

export default logout
