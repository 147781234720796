import { combineReducers, configureStore } from '@reduxjs/toolkit'
import userSlice from './userSlice'
import studentClassroomSlice from './student/studentClassroomsSlice'
import lessonsSlice from './student/lessonsSlice'
import dailyBeatsSlice from './student/dailyBeatsSlice'
import miscSlice from './miscSlice'
import classroomsSlice from './teacher/classroomsSlice'
import lessonSummariesSlice from './student/lessonSummariesSlice'
import dailyBeatsSummariesSlice from './teacher/dailyBeatsSummariesSlice'
import schoolsSlice from './admin/schoolsSlice'
import districtsSlice from './admin/districtsSlice'
import teachersSlice from './admin/teachersSlice'
import studentsSlice from './teacher/studentsSlice'
import focusSeshSlice from './student/focusSeshSlice'
import focusSeshPresetsSlice from './student/focusSeshPresetsSlice'
import focusSeshLogsSlice from './student/focusSeshLogsSlice'

export const ROOT_ACTIONS = {
  RESET: { type: 'RESET' },
}

const combinedReducer = combineReducers({
  // Student:
  user: userSlice,
  studentClassrooms: studentClassroomSlice,
  dailyBeats: dailyBeatsSlice,
  lessons: lessonsSlice,

  // Focus Sesh
  focusSesh: focusSeshSlice,
  focusSeshPresets: focusSeshPresetsSlice,
  focusSeshLogs: focusSeshLogsSlice,

  // Teacher:
  classrooms: classroomsSlice,
  lessonSummaries: lessonSummariesSlice,
  dailyBeatsSummaries: dailyBeatsSummariesSlice,
  students: studentsSlice,

  // Admin:
  schools: schoolsSlice,
  districts: districtsSlice,
  teachers: teachersSlice,

  // Misc:
  misc: miscSlice
})

const rootReducer = (state, action) => {
  if (action.type === ROOT_ACTIONS.RESET.type) {
    state = undefined
  }
  return combinedReducer(state, action)
}

export default configureStore({
  reducer: rootReducer,
})
