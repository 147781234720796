import { createSlice } from '@reduxjs/toolkit'
import moment from 'moment'

export const selectDistricts = (state) => state.districts
export const selectDistrictByName = (name) => (state) => state.districts.find((district) => district.name === name)

const districtsSlice = createSlice({
  name: 'districts',
  initialState: [{
    name: null, // string
    id: null, // number
  }],
  reducers: {
    loadDistricts: (state, {
      payload: districts,
    }) => districts.map((district) => ({
      name: district.name,
      id: district.id,
    })),
    addDistrict: (state, {
      payload: district
    }) => {
      state.push(district)
    }
  },
})

export const { loadDistricts, addDistrict } = districtsSlice.actions

export default districtsSlice.reducer
