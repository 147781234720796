const ENVIRONMENT = process.env.NEXT_PUBLIC_ENVIRONMENT

const config = {
  prod: {
    isTest: false,
    featureFlags: {
      lessonCompletionEnabled: true,
      enableBlackoutDays: true,
      enableMoveStudents: true,
    }
  },
  staging: {
    isTest: false,
    featureFlags: {
      lessonCompletionEnabled: true,
      enableBlackoutDays: true,
      enableMoveStudents: true,
    }
  },
  test: {
    isTest: true,
    featureFlags: {
      lessonCompletionEnabled: true,
      enableBlackoutDays: true,
      enableMoveStudents: true,
    }
  },
  dev: {
    isTest: false,
    featureFlags: {
      lessonCompletionEnabled: true,
      enableBlackoutDays: true,
      enableMoveStudents: true,
    }
  }
}

export default config[ENVIRONMENT] || config.dev
