import { styled, Typography } from '@mui/material'
import Image from "next/image"
import { useRouter } from 'next/router'
import BottomSheet from "../BottomSheet"
import { navigationPush } from "../../../navigation/studentPages"
import notLoggedInPages from "../../../navigation/notLoggedInPages"
import { useEffect, useState } from "react"
import { isInNativeApp } from "../../../utils/utils"
import { isMobile } from "react-device-detect"
import { getSeenAppUpsellBottomSheet, setSeenAppUpsellBottomSheet } from "../../../utils/localStorage"

const Container = styled ('div')({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
})

const Header = styled(Typography)(({ theme }) => ({
    maxWidth: '328px',
    textAlign: 'center',
    fontSize: '16px',
    fontWeight: 'bold',
    lineHeight: '24px',
    color: 'black',
    [theme.breakpoints.down('sm')]: {
        fontSize: '16px',
        lineHeight: '24px',
    },
}))

const DownloadAppRow = styled('div')({
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    columnGap: '24px',
    margin: '24px 0px 30px 0px',
})

const AppIconAndTitleRow = styled('div')({
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    columnGap: '8px',
})

const AppIcon = styled(Image)({
})

const DownloadAppButton = styled('div')(({ theme }) => ({
    height: '50px',
    width: '120px',
    borderRadius: '24px',
    backgroundColor: '#232538',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
}))

const ContinueInBrowser = styled(Typography)({
    color: 'black',
    textDecoration: 'underline',
})

const AppUpsellBottomSheet = () => {
    const { pathname, ...router} = useRouter()
    const dontShowAppUpsellPathnames = ['/', '/marketingRequestAnAccount', '/app-download-redirect']

    // Determine whether to show component
    // if user is already logged in, get jwt for native app deeplink
    const [showAppUpsellBottomSheet, setShowAppUpsellBottomSheet] = useState(false)
    useEffect(() => {
        const isOnDontShowAppUpsellPath = dontShowAppUpsellPathnames.includes(pathname)
        const isNativeApp = isInNativeApp()
        const hasSeenAppUpsellWithin24Hours = getSeenAppUpsellBottomSheet()
        setShowAppUpsellBottomSheet(isMobile && !isNativeApp && !isOnDontShowAppUpsellPath && !hasSeenAppUpsellWithin24Hours)
    }, [pathname])

    return <BottomSheet
        showBottomSheet={showAppUpsellBottomSheet}
        customBody={<Container>
            <Header>Get access to all of our best features with the Finding Focus app:</Header>
            <DownloadAppRow>
                <AppIconAndTitleRow>
                    <AppIcon src={require('/public/static/finding-focus-app-icon.png')} height={50} width={50}/>
                    <Typography variant={'h6'} style={{color: 'black'}}>Finding Focus</Typography>
                </AppIconAndTitleRow>
                <DownloadAppButton onClick={() => {
                    setShowAppUpsellBottomSheet(false)
                    navigationPush(notLoggedInPages.appDownloadRedirect.getRoute(), router, notLoggedInPages.appDownloadRedirect.v1)
                }}>
                    <Typography variant={'body2'} style={{color: 'white'}}>Open</Typography>
                </DownloadAppButton>
            </DownloadAppRow>
            <ContinueInBrowser
                variant={'body1'}
                onClick={() => {
                    setShowAppUpsellBottomSheet(false)
                    setSeenAppUpsellBottomSheet()
                }}
            >
                Continue in browser
            </ContinueInBrowser>
        </Container>}

        />
}

export default AppUpsellBottomSheet
