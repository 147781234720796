import { useContext, createContext, useState, useEffect, useMemo } from 'react'
import { useSelector } from 'react-redux'
import { selectUser } from '../reducers/userSlice'
import { COLOR_SCHEME_PREFERENCES } from '../constants/user'
import { isTestEnv } from '../tests/utils'

const DrawerWidthContext = createContext()


export const useDrawerWidthContext = () => useContext(DrawerWidthContext)

export const DrawerWidthProvider = ({ children }) => {
  const [drawerWidth, setDrawerWidth] = useState(0)
  return <DrawerWidthContext.Provider value={[drawerWidth, setDrawerWidth]}>{children}</DrawerWidthContext.Provider>
}
