import '../styles/globals.css'
import { ThemeProvider } from '@mui/material'
import studentTheme from '../theme/studentTheme'
import { Provider } from 'react-redux'
import store from '../reducers/store'
import { LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment'
import { ColorSchemeProvider } from '../contexts/ColorSchemeContext'
import { useEffect } from 'react'
import { ImagesToPreload } from '../utils/imagesToPreload'
import { GoogleOAuthProvider } from '@react-oauth/google'
import AppUpsellBottomSheet from '../components/base/misc/AppUpsellBottomSheet'
import { DrawerWidthProvider } from '../contexts/DrawerWidthContext'

var MyApp = ({ Component, pageProps }) => {
  // Preload images
  useEffect(() => {
    for (const imageUrl of ImagesToPreload) {
      const image = new Image()
      image.src = imageUrl.trim()
    }

  }, [])
  return (
    <Provider store={store}>
      <GoogleOAuthProvider clientId={'918274715818-sma980hmo0fk3mngoafhq1rg04ttl1m2.apps.googleusercontent.com'}>
        <DrawerWidthProvider>
          <ThemeProvider theme={studentTheme}>
            <LocalizationProvider dateAdapter={AdapterMoment}>
              <ColorSchemeProvider>
                <Component {...pageProps} />
                <AppUpsellBottomSheet />
              </ColorSchemeProvider>
            </LocalizationProvider>
          </ThemeProvider>
        </DrawerWidthProvider>
      </GoogleOAuthProvider>
    </Provider>
  )
}

export default MyApp
