import { createSlice } from '@reduxjs/toolkit'

export const selectStudentClassrooms = (state) => state.studentClassrooms
export const selectClassroomId = (state) => state.studentClassrooms[0]?.classroomId
export const selectCourseVersion = (state) => state.studentClassrooms[0]?.courseVersion

// example classroom: {
//   classroomId: null, // number
//   isStudent: null, // bool
//   createdAt: null, // str (timestamp)
//   updatedAt: null, // str (timestamp)
//   name: null, // str
//   teacherLastName: null, // str
//   startDate: null, // str (timestamp)
//   defaultSchedule: null, // bool
//   inviteCode: null, // str
//   courseVersion: null, // number
//   latestCourseVersion: null, // number
// },

const studentClassroomsSlice = createSlice({
  name: 'studentClassrooms',
  initialState: [],
  reducers: {
    loadStudentClassrooms: (state, { payload: classrooms }) =>
      classrooms.map((classroom) => ({
        classroomId: classroom.classroomId,
        isStudent: classroom.isStudent,
        createdAt: classroom.createdAt,
        updatedAt: classroom.updatedAt,
        name: classroom.name,
        teacherLastName: classroom.teacherLastName,
        startDate: classroom.startDate,
        defaultSchedule: classroom.defaultSchedule,
        inviteCode: classroom.inviteCode,
        courseVersion: classroom.courseVersion,
        latestCourseVersion: classroom.latestCourseVersion,
      }))
  },
})

export const { loadStudentClassrooms } = studentClassroomsSlice.actions

export default studentClassroomsSlice.reducer
