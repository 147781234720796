import moment from 'moment'
import { loadStudentClassrooms } from '../reducers/student/studentClassroomsSlice'
import { getDetailedLesson, getInitialDailyBeats, getInitialLessons } from '../requests/studentRequests'
import { loadDetailedLessonData, loadInitialLessonData } from '../reducers/student/lessonsSlice'
import { loadDailyBeats } from '../reducers/student/dailyBeatsSlice'
import { pullUserFromServer } from '../logic/pullUserFromServer'

export const getMomentDateNow = () => moment().format(moment.HTML5_FMT.DATE)

export const getMomentDateTimeNow = () => moment().format('YYYY-MM-DD HH:mm:ss')

export const getMomentDateTimeInXUnitsFromNow = (quantity, unit) => moment().add(quantity, unit).format('YYYY-MM-DD HH:mm:ss')

export const remoteImageLoader = ({ src }) => {
  return src
}

export const stringContainsLetter = (string) => {
  const regExp = /[a-zA-Z]/g
  return regExp.test(string)
}

export const formatFloatAsPercent = (number, decimalDigits = 0, capAt100Percent = false) => {
  if (isNaN(number)) {
    return `0%`
  }
  if (capAt100Percent) {
    return `${Math.min(100, number * 100).toFixed(decimalDigits)}%`
  } else {
    return `${(number * 100).toFixed(decimalDigits)}%`
  }
}

export const stringContainsNumber = (string) => {
  const regExp = /[0-9]/g
  return regExp.test(string)
}

export const momentStartDate = (startDate) => {
  return moment(startDate)
}

export const isProd = () => {
  return process.env.NEXT_PUBLIC_ENVIRONMENT === 'prod'
}

// Validates date is in mm/dd/yyyy format
export const isValidDateString = (dateStr) => {
  return !!dateStr && /^[0-9]{1,2}[\/\-][0-9]{1,2}[\/\-]\d{4}$/.test(dateStr)
}


export const validateEmail = (email) => {
  return String(email)
    .toLowerCase()
    .match(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
    )
}

export const sleep = ms => new Promise(r => setTimeout(r, ms))

export const postMessageToNativeApp = (message) => {
  if (window?.ReactNativeWebView) {
    window.ReactNativeWebView.postMessage(JSON.stringify(message))
  }
}

export const isInNativeApp = () => {
  return !!window.ReactNativeWebView
}

export const getRandomFloat = (min, max, decimals) => {
  const str = (Math.random() * (max - min) + min).toFixed(decimals)

  return parseFloat(str)
}

export const getRandomInt = (min, max) => {
  const roundedMin = Math.ceil(min)
  const roundedMax = Math.floor(max)
  return Math.floor(Math.random() * (roundedMax - roundedMin) + roundedMin) // The maximum is exclusive and the minimum is inclusive
}

export const shuffleArray = (array) => {
  let currentIndex = array.length, randomIndex

  // While there remain elements to shuffle.
  while (currentIndex != 0) {

    // Pick a remaining element.
    randomIndex = Math.floor(Math.random() * currentIndex)
    currentIndex--;

    // And swap it with the current element.
    [array[currentIndex], array[randomIndex]] = [
      array[randomIndex], array[currentIndex]]
  }

  return array
}

export const addCourseDayToLessonAndDailyBeatsData = (lessonAndDailyBeatsData) => {
  // Add Course Day value to each piece of content.
  // TODO: revert this logic back to content.courseDay = i+1 if we ever deprecate old classrooms with more than one piece of content unlocking per day
  lessonAndDailyBeatsData.map((content, i) => {
    if (i === 0) {
      content.courseDay = 1
    } else if (content.unlockDate === lessonAndDailyBeatsData[i-1].unlockDate) {
      content.courseDay = lessonAndDailyBeatsData[i-1].courseDay
    } else {
      content.courseDay = lessonAndDailyBeatsData[i-1].courseDay + 1
    }
  })
  return lessonAndDailyBeatsData
}

export const getContentDurationFromLabel = (contentLabel, isShortenedCourse = true) => {
  // Had to make this backwards compatible in case of viewing classroom drilldown or course schedule of old courses
  // Lesson 1 in Shortened Course is 5 min. Otherwise, all Lessons are 12 min. and all Daily Beats are 5 min.
  if (isShortenedCourse && contentLabel === 'Lesson 1') {
    return '7 min.'
  } else {
    return contentLabel.includes('Daily Beat') ? '5 min.' : '12 min.'
  }
}

export const addContentDurationToLessonAndDailyBeatsData = (lessonAndDailyBeatsData) => {
  const isShortenedCourse = lessonAndDailyBeatsData.length === 10
  // Map duration onto each piece of content
  lessonAndDailyBeatsData.map((content) =>
    content.duration = getContentDurationFromLabel(content.label, isShortenedCourse)
  )
}

export const getSortedLessonAndDailyBeatsDataFromClassroom = (classroom) => {
  const lessonsData = classroom ? classroom.lessonUnlockDates
          .map((unlockDate, i) => ({
            unlockDate,
            type: 'lesson',
            index: i,
            label: `Lesson ${i + 1}`,
          }))
      : []
  const dailyBeatsData = classroom ? classroom.dailyBeatUnlockDates
          .map((unlockDate, i) => ({
            unlockDate,
            type: 'dailyBeat',
            index: i,
            label: `Daily Beat ${i + 1}`,
          }))
      : []
  // Merge objects into a single object of data for each piece of content in the course, sorted by unlockDate
  const lessonAndDailyBeatsData = lessonsData.concat(dailyBeatsData)
  lessonAndDailyBeatsData.sort((a, b) => {
    if (a.unlockDate < b.unlockDate) {
      return -1
    } else if (a.unlockDate > b.unlockDate) {
      return 1
    } else {
      return a.type === 'lesson' ? -1 : 1
    }
  })
  addCourseDayToLessonAndDailyBeatsData(lessonAndDailyBeatsData)
  addContentDurationToLessonAndDailyBeatsData(lessonAndDailyBeatsData)
  return lessonAndDailyBeatsData
}

export const capitalizeFirstLetterOfEachWord = (string) => {
  return string.split(" ").map((word) => word.charAt(0).toUpperCase() + word.substring(1)).join(" ")
}

export const getInternalDomainByEnvironment = () => {
  if (process.env.NEXT_PUBLIC_ENVIRONMENT === 'prod') {
    return 'https://portal.findingfocus.app'
  } else if (process.env.NEXT_PUBLIC_ENVIRONMENT === 'staging') {
    return 'https://beta-portal.findingfocus.app'
  } else if (process.env.NEXT_PUBLIC_ENVIRONMENT === 'dev') {
    return 'https://finding-focus-frontend.ngrok.io'
  }
}

export const getServerDomainByEnvironment = () => {
  if (process.env.NEXT_PUBLIC_ENVIRONMENT === 'prod') {
    return 'https://services.findingfocus.app'
  } else if (process.env.NEXT_PUBLIC_ENVIRONMENT === 'staging') {
    return 'https://beta-services.findingfocus.app'
  } else if (process.env.NEXT_PUBLIC_ENVIRONMENT === 'dev') {
    return 'http://localhost:3002'
  }
}

export const getStartOfDayForDate = (date) => {
  return moment(date).startOf('day').toISOString()
}

export const getEndOfDayForDate = (date) => {
  return moment(date).endOf('day').toISOString()
}

export const getTotalFocusSeshTimeInMinutes = (logs) => {
  return Math.round(logs.reduce((sum, log) => log.secondsDuration + sum, 0) / 60)
}

// Returns 'NUM min' if total time is less than 60 mins
// Returns 'NUM hr' rounded to nearest half hour if total time is more than 60 mins
export const getTotalFocusSeshTimeInMinutesOrHours = (logs) => {
  const totalTimeInMinutes = Math.round(logs.reduce((sum, log) => log.secondsDuration + sum, 0) / 60)
  let totalTime
  if (totalTimeInMinutes < 60) {
    totalTime = `${totalTimeInMinutes} min`
  } else if (totalTimeInMinutes >= 60) {
    // Calculate total minutes into hours, rounded to the nearest half hour
    const totalTimeInHours = Math.round(totalTimeInMinutes / 30) / 2
    totalTime = `${totalTimeInHours} hr`
  }
  return totalTime
}

// Get data from logs for today, this week, and all time
export const getFocusSeshUsageDataFromLogs = (logs) => {
  const sessions = logs.length
  const time = getTotalFocusSeshTimeInMinutesOrHours(logs)
  const checkIns = logs.reduce((sum, log) => log.numCheckIns + sum, 0)
  return {
    sessions,
    time,
    checkIns,
  }
}

export const loadStudentClassroomsLessonsAndDailyBeats = async (dispatch) => {
  const userData = await pullUserFromServer()

  const classrooms = userData.classrooms.map((classroom) => ({
    classroomId: classroom.classroomId,
    completed: classroom.completed,
    isStudent: classroom.isStudent,
    createdAt: classroom.createdAt,
    updatedAt: classroom.updatedAt,
    name: classroom['classroom.name'],
    teacherLastName: classroom['classroom.classroomTeacher.lastName'],
    startDate: classroom['classroom.startDate'],
    defaultSchedule: classroom['classroom.defaultSchedule'],
    inviteCode: classroom['classroom.code'],
    courseVersion: classroom['classroom.course.version'],
    latestCourseVersion: classroom.latestCourseVersion,
  }))
  if (classrooms.length) {
    dispatch(loadStudentClassrooms(classrooms))
  }

  const { classroomId } = classrooms[0]

  // Load Lessons Data
  const lessonsData = await getInitialLessons(classroomId)
  dispatch(loadInitialLessonData(lessonsData))
  await Promise.all(lessonsData.map((lesson) =>
    getDetailedLesson(classroomId, lesson.id)
      .then((detailedLesson) => {
        dispatch(loadDetailedLessonData(detailedLesson))
      })
      .catch((e) => e)))

  // Load Daily Beats Data
  const dailyBeatsData = await getInitialDailyBeats(classroomId)
  dispatch(loadDailyBeats(dailyBeatsData))
}
