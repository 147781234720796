import { createSlice } from '@reduxjs/toolkit'

export const selectIsTimed = (state) => state.focusSesh.isTimed
export const selectDurationMinutes = (state) => state.focusSesh.durationMinutes
export const selectDurationSeconds = (state) => state.focusSesh.durationSeconds
export const selectCheckInFrequency = (state) => state.focusSesh.checkInFrequency
export const selectCheckInSound = (state) => state.focusSesh.checkInSound
export const selectStartAndEndSound = (state) => state.focusSesh.startAndEndSound
export const selectSeshInitialized = (state) => state.focusSesh.initialized
export const selectCheckInTimes = (state) => state.focusSesh.checkInTimes
export const selectSoundtrack = (state) => state.focusSesh.soundtrack
export const selectSoundtrackAllowed = (state) => state.focusSesh.soundtrackAllowed

export const presetFrequencies = [
  '2-3 Minutes',
  '3-5 Minutes',
  '5-7 Minutes',
  '7-10 Minutes',
]

const focusSeshSlice = createSlice({
  name: 'focusSesh',
  initialState: {
    isTimed: true, // bool
    durationMinutes: 0, // number
    durationSeconds: 0, // str
    checkInFrequency: null, // str
    initialized: false, // bool
    checkInTimes: null, // array
    checkInSound: null, // str
    startAndEndSound: null, // str
    soundtrackAllowed: null, // bool
    soundtrack: null, // str
  },
  reducers: {
    setIsTimed: (state, {
      payload: {
        isTimed,
      },
    }) => {
      state.initialized = false
      state.isTimed = isTimed
    },
    setDurationMinutes: (state, {
      payload: {
        durationMinutes,
      },
    }) => {
      state.initialized = false
      state.durationMinutes = durationMinutes
    },
    setDurationSeconds: (state, {
      payload: {
        durationSeconds,
      },
    }) => {
      state.initialized = false
      state.durationSeconds = durationSeconds
    },
    setCheckInFrequency: (state, {
      payload: {
        checkInFrequency,
      },
    }) => {
      state.initialized = false
      state.checkInFrequency = checkInFrequency
    },
    setStartAndEndSound: (state, {
      payload: {
        startAndEndSound,
      },
    }) => {
      state.initialized = false
      state.startAndEndSound = startAndEndSound
    },
    setCheckInSound: (state, {
      payload: {
        checkInSound,
      },
    }) => {
      state.initialized = false
      state.checkInSound = checkInSound
    },
    setSoundtrackAllowed: (state, {
      payload: {
        soundtrackAllowed,
      },
    }) => {
      state.initialized = false
      state.soundtrackAllowed = soundtrackAllowed
    },
    setSoundtrack: (state, {
      payload: {
        soundtrack,
      },
    }) => {
      state.soundtrack = soundtrack
    },
    setInitialized: (state, {
      payload: checkInTimes,
    }) => {
      state.initialized = true
      state.checkInTimes = checkInTimes
    },
  },
})

export const {
  setIsTimed,
  setDurationSeconds,
  setDurationMinutes,
  setCheckInFrequency,
  setInitialized,
  setStartAndEndSound,
  setCheckInSound,
  setSoundtrackAllowed,
  setSoundtrack,
} = focusSeshSlice.actions

export default focusSeshSlice.reducer
