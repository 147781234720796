import store from '../reducers/store'
import { postMessageToNativeApp } from '../utils/utils'
import { nativeMessageTypes } from '../constants/nativeMessageTypes'
import { getDailyBeatPreference, getUser } from '../requests/studentRequests'
import { loadUser } from '../reducers/userSlice'

export const pullUserFromServer = async () => {
  const { dispatch } = store
  const userData = await getUser()
  const dailyBeatsPreference = await getDailyBeatPreference()
  dispatch(loadUser({
    id: userData.id,
    firstName: userData.firstName,
    lastName: userData.lastName,
    emailAddress: userData.emailAddress,
    role: userData.role,
    validated: userData.validated,
    deleted: userData.deleted,
    schoolId: userData.schoolId,
    publicId: userData.publicId,
    canManageAdmins: userData.canManageAdmins,
    mustAcceptTerms: userData.mustAcceptTerms,
    summaryOfTermsUpdates: userData.summaryOfTermsUpdates,
    completedTeacherOrientation: userData.completedTeacherOrientation,
    dismissedStudentViewCallout: userData.dismissedStudentViewCallout,
    seenWelcomeToStudentView: userData.seenWelcomeToStudentView,
    coursePoints: userData.classrooms[0].coursePoints,
    totalCoursePoints: userData.classrooms[0].totalCoursePoints,
    pointsPerLessonCompletion: userData.pointsPerLessonCompletion,
    pointsPerDailyBeatCompletion: userData.pointsPerDailyBeatCompletion,
    colorSchemePreference: userData.colorSchemePreference,
    dailyBeatsPreference,
    teacherVerificationStatus: userData.teacherVerificationStatus,
    classroomId: userData.classrooms[0].classroomId,
    seenFocusSeshPreSetupOrientation: userData.seenFocusSeshPreSetupOrientation,
    seenFocusSeshPreSeshOrientation: userData.seenFocusSeshPreSeshOrientation,
    seenFocusSeshPreSeshOrientationStatus: userData.seenFocusSeshPreSeshOrientationStatus,
    showCompletionGraph: userData.showCompletionGraph,
    seenAgeAndConsentDisclaimerStatus: userData.seenAgeAndConsentDisclaimerStatus,
    lastSeenStudentInterfaceVersion: userData.lastSeenStudentInterfaceVersion,
    seenImportantInfoAboutNewClass: userData.seenImportantInfoAboutNewClass,
    seenTenDayCourseTabInfo: userData.seenTenDayCourseTabInfo,
    dismissedArchivingClassesCallout: userData.dismissedArchivingClassesCallout,
  }))
  postMessageToNativeApp({
    type: nativeMessageTypes.reloadUser
  })
  return userData

}
