import { styled, Drawer } from '@mui/material'

const StyledBottomSheet = styled(Drawer)({
    '& .MuiPaper-root': {
        backgroundColor: 'transparent',
    }
})

const BottomSheetContainer = styled('div', {
    shouldForwardProp: (prop) => prop !== 'backgroundColor',
})(({ backgroundColor, borderRadius, padding }) => ({
    height: '100%',
    backgroundColor: backgroundColor ? backgroundColor : '#fff',
    borderRadius: borderRadius ? borderRadius : '16px 16px 0px 0px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: padding ? padding : '16px 16px 20px 16px',
}))

// For more information about props for the MUI Drawer component, visit: https://mui.com/material-ui/api/drawer/
const BottomSheet = ({
                       anchor = 'bottom',
                       borderRadius,
                       backgroundColor,
                       customBody,
                       padding,
                       showBottomSheet,
                       transitionDuration = 250,
                       variant = 'temporary',
            }) => {
    return <StyledBottomSheet
            anchor={anchor}
            open={showBottomSheet}
            transitionDuration={transitionDuration}
            variant={variant}
        >
            <BottomSheetContainer
                backgroundColor={backgroundColor}
                borderRadius={borderRadius}
                padding={padding}
            >
                {customBody}
            </BottomSheetContainer>
        </StyledBottomSheet>
}

export default BottomSheet
